<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" dense flat>
        <v-toolbar-title>Target Info</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon to="/targets">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-tabs>
          <v-tab :to="`/target/${id}`">Targets</v-tab>
          <v-tab :to="`/target/${id}/employees`">Assigned Employees</v-tab>
          <v-tab :to="`/target/${id}/skus`">Sku</v-tab>
        </v-tabs>

        <v-row>
          <v-divider></v-divider>
        </v-row>

        <router-view />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      salesEmployee: {},
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getData",
      immediate: true,
    },
  },
  methods: {
    getData(val) {
      const self = this;
      self.id = val;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee/${val}`)
        .then((res) => {
          self.salesEmployee = res;
          self.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
</style>